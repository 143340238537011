<template>
  <div>
    <!-- Start Breadcrump Area  -->
    <div
      class="breadcrumb-area rn-bg-color ptb--120 bg_image bg_image--1"
      data-black-overlay="6"
    >
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="breadcrumb-inner pt--100 pt_sm--40 pt_md--50">
              <h2 class="heading-title">Plan</h2>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start team Area  -->
    <div class="rn-team-area bg_color--1 ptb--120">
      <v-container>
        <v-row>
          <v-col lg="12">
            <PricingPlan v-on:updateplan="update()"> </PricingPlan>
          </v-col>
        </v-row>
        <!-- End row -->
      </v-container>
    </div>
    <!-- Start team Area  -->
  </div>
</template>

<script>
import PricingPlan from "../../components/pricing-plan/PricingPlanUser";

export default {
  components: {
    PricingPlan
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: "Home",
          to: "/",
          disabled: false
        },
        {
          text: "Plan",
          to: "",
          disabled: true
        }
      ]
    };
  },
  methods: {
    update() {
      this.$router.push({ name: "Dashboard" });
    }
  }
};
</script>
